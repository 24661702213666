var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',_vm._b({attrs:{"min-height":"600px","type":"article, article, article, actions"}},'v-skeleton-loader',_vm.attrs,false))],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Datos generales del proyecto ")])],1)],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","label":"Nombre del proyecto","rules":[
                                    _vm.required('nombre del proyecto'),
                                    _vm.minLength('nombre del proyecto', 5),
                                    _vm.maxLength('nombre del proyecto', 500)
                                ],"maxlength":"500"},model:{value:(_vm.datosItem.nombreProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "nombreProyecto", $$v)},expression:"datosItem.nombreProyecto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","label":"Objetivo","rules":[
                                    _vm.required('objetivo'),
                                    _vm.minLength('objetivo', 5),
                                    _vm.maxLength('objetivo', 1000)
                                ],"rows":"2","maxlength":"500"},model:{value:(_vm.datosItem.objetivo),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivo", $$v)},expression:"datosItem.objetivo"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Monto suscrito","rules":[
                                    _vm.decimals('monto suscrito')
                                ],"maxlength":"30","prefix":"GTQ"},model:{value:(_vm.montoSuscrito),callback:function ($$v) {_vm.montoSuscrito=$$v},expression:"montoSuscrito"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Fecha de inicio","rules":[
                                    _vm.required('fecha de inicio'),
                                    _vm.minLength('fecha de inicio', 8),
                                    _vm.dateFormat('fecha de inicio')
                                ],"maxlength":"10"},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","disabled":false,"label":"Fecha de fin","rules":[
                                    _vm.required('fecha de fin'),
                                    _vm.minLength('fecha de fin', 8),
                                    _vm.dateFormat('fecha de fin')
                                ],"maxlength":"10"},model:{value:(_vm.fechaFinalizacion),callback:function ($$v) {_vm.fechaFinalizacion=$$v},expression:"fechaFinalizacion"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.clasificacionRecursos,"color":"blue-grey lighten-2","label":"Clasificación de recursos","item-text":"recursos","item-value":"id","rules":[_vm.selectRequired('clasificación recursos')],"menu-props":"auto"},model:{value:(_vm.datosItem.clasificacionRecursosId),callback:function ($$v) {_vm.$set(_vm.datosItem, "clasificacionRecursosId", $$v)},expression:"datosItem.clasificacionRecursosId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","label":"Principal resultado","rules":[
                                    _vm.required('principal resultado'),
                                    _vm.minLength('principal resultado', 5),
                                    _vm.maxLength('principal resultado', 500)
                                ],"rows":"3","maxlength":"500"},model:{value:(_vm.datosItem.principalResultado),callback:function ($$v) {_vm.$set(_vm.datosItem, "principalResultado", $$v)},expression:"datosItem.principalResultado"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","dark":"","dense":"","filled":"","type":"submit","loading":_vm.btnRegistroLoading},on:{"disabled":function($event){!_vm.validForm}}},[_vm._v(" "+_vm._s(_vm.btnRegistroText)+" ")])],1)],1)],1)],1)],1),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText,"transition":"scroll-y-transition"}}),_c('SnackAlert',{ref:"snackalert"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }