<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">
    <!--inicio:: condiciones de instituciones beneficiarias -->
        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-subheader class="text-h5 black--text">
                            Vinculación Marcos de desarrollo
                        </v-subheader>
                
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-form 
                        ref="formCobertura"
                        v-on:submit.prevent="registrarCobertura"
                        v-model="validForm"
                        >

     

                    <v-row class="mt-4">
                        <!--inicio:: pnd -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.pndId"
                                :items="pnds"
                                :loading="ddPndLoading"
                                dense
                                class="required"
                                filled
                                label="PND"
                                item-text="nombrePND"
                                item-value="id"
                                @change="obtenerMetasPnd(datosCobertura.pndId)"
                                :no-data-text="
                                    pnds != null
                                    ? 'Seleccione PND'
                                    : 'No se han encontrado PND'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('pnd')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.metaPNDId"
                                :items="metasPnd"
                                :loading="ddMetasPndLoading"
                                dense
                                class="required"
                                filled
                                label="Meta PND"
                                item-text="meta"
                                item-value="id"
                                :no-data-text="
                                    metasPnd != null
                                    ? 'Seleccione una meta PND'
                                    : 'No se han encontrado metas'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('meta pnd')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <!--fin :: pnd -->
                    </v-row>


                    <v-row>
                        <!--inicio:: ods -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.odsId"
                                :items="ods"
                                :loading="ddOdsLoading"
                                dense
                                class="required"
                                filled
                                label="ODS"
                                item-text="nombreODS"
                                item-value="id"
                                @change="obtenerMetasOds(datosCobertura.odsId)"
                                :no-data-text="
                                    pnds != null
                                    ? 'Seleccione ODS'
                                    : 'No se han encontrado ODS'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('Ods primario')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.metasODSId"
                                :items="metasOds"
                                :loading="ddMetasOdsLoading"
                                dense
                                class="required"
                                filled
                                label="Meta ODS"
                                item-text="meta"
                                item-value="id"
                                :no-data-text="
                                    metasOds != null
                                    ? 'Seleccione una meta ODS'
                                    : 'No se han encontrado metas ODS'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('meta ods')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <!--fin :: ods -->

                        <!-- inicio:: ods secundario-->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.odsSecundarioId"
                                :items="ods"
                                :loading="ddOdsLoading"
                                dense
                                filled
                                label="ODS Secundario"
                                item-text="nombreODS"
                                item-value="id"
                                :no-data-text="
                                    pnds != null
                                    ? 'Seleccione ODS secundario'
                                    : 'No se han encontrado ODS'
                                "
                                menu-props="auto"
                                >
                            </v-select>
                        </v-col>

                        <!--fin :: ods -->
                    </v-row>


                    <v-row>
                        <!--inicio:: katun -->
                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.ejesKatunId"
                                :items="ejesKatun"
                                :loading="ddEjesKatunLoading"
                                dense
                                class="required"
                                filled
                                label="Eje K´atun"
                                item-text="nombreEje"
                                item-value="id"
                                @change="obtenerPrioridadesKatun(datosCobertura.ejesKatunId)"
                                :no-data-text="
                                    ejesKatun != null
                                    ? 'Seleccione Eje K´atun'
                                    : 'No se han encontrado Ejes K´atun'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('Eje K´atun')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.prioridadesKatunId"
                                :items="prioridadesKatun"
                                :loading="ddPrioridadesKatunLoading"
                                dense
                                class="required"
                                filled
                                label="Prioridad K´atun"
                                item-text="prioridad"
                                item-value="id"
                                @change="obtenerMetasKatun(datosCobertura.prioridadesKatunId)"
                                :no-data-text="
                                    prioridadesKatun != null
                                    ? 'Seleccione una prioridad K´atun'
                                    : 'No se han encontrado prioridades K´atun'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('prioridad K´atun')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.metasKatunId"
                                :items="metasKatun"
                                :loading="ddMetasKatunLoading"
                                dense
                                class="required"
                                filled
                                label="Meta K´atun"
                                item-text="meta"
                                item-value="id"
                                :no-data-text="
                                    metasKatun != null
                                    ? 'Seleccione una meta K´atun'
                                    : 'No se han encontrado metas K´atun'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('Meta k´atun')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <!--fin :: katun -->
                    </v-row>



                    <v-row>
                        <!--inicio:: pgg -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.pggId"
                                :items="pggs"
                                :loading="ddPggLoading"
                                dense
                                class="required"
                                filled
                                label="PGG"
                                item-text="nombrePGG"
                                item-value="id"
                                @change="obtenerPilaresPgg(datosCobertura.pggId)"
                                :no-data-text="
                                    pggs != null
                                    ? 'Seleccione pgg'
                                    : 'No se han encontrado pgg'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('pgg')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <!--inicio:: pgg -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.pilarPGGId"
                                :items="pilaresPgg"
                                :loading="ddPilaresPggLoading"
                                dense
                                class="required"
                                filled
                                label="Pilar PGG"
                                item-text="pilar"
                                item-value="id"
                                @change="obtenerMetasEstrategicas(datosCobertura.pilarPGGId)"
                                :no-data-text="
                                    pilaresPgg != null
                                    ? 'Seleccione un pilar pgg'
                                    : 'No se han encontrado pilares pgg'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('pilar pgg')
                                ]"
                                >
                            </v-select>
                        </v-col>


                        <!--inicio:: metas estrategucas pgg -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.metasEstrategicasPGGId"
                                :items="metasEstrategicasPgg"
                                :loading="ddMetasEstrategicasPggLoading"
                                dense
                                class="required"
                                filled
                                label="Meta estretégica PGG"
                                item-text="meta"
                                item-value="id"
                                @change="obtenerObjetivosEstrategicosPgg(datosCobertura.metasEstrategicasPGGId)"
                                :no-data-text="
                                    metasEstrategicasPgg != null
                                    ? 'Seleccione una meta estraégica pgg'
                                    : 'No se han encontrado metas estratégicas pgg'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('Meta estrategica pgg')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <!--inicio:: objetivos estrategucos pgg -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.objetivosEstrategicosPGGId"
                                :items="objetivosEstrategicosPgg"
                                :loading="ddObjetivosEstrategicosPggLoading"
                                dense
                                filled
                                label="Objetivo estretégico PGG"
                                item-text="objetivo"
                                item-value="id"
                                @change="obtenerObjetivosSectorialessPgg(datosCobertura.objetivosEstrategicosPGGId)"
                                :no-data-text="
                                    objetivosEstrategicosPgg != null
                                    ? 'Seleccione un objetivo estratégico pgg'
                                    : 'No se han encontrado objetivos estratégicos pgg'
                                "
                                menu-props="auto"
                                >
                            </v-select>
                        </v-col>


                        <!--inicio:: objetivos sectoriales pgg -->
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.objetivosSectorialesPGGId"
                                :items="objetivosSectorialesPgg"
                                :loading="ddObjetivosSectorialesPggLoading"
                                dense
                                filled
                                label="Objetivo sectorial pgg"
                                item-text="objetivo"
                                item-value="id"
                                :no-data-text="
                                    objetivosSectorialesPgg != null
                                    ? 'Seleccione un objetivo sectorial pgg'
                                    : 'No se han encontrado objetivos sectoriales pgg'
                                "
                                menu-props="auto"
                                >
                            </v-select>
                        </v-col>

                    </v-row>
                    <!--fin: pgg -->

                    <v-row>
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                        
                            <v-btn
                                color="light-blue-502"
                                class="white--text mb-2 float-right mt-2"
                                type="submit"
                                :elevation="0"
                                :disabled="!validForm"
                                :loading="btnRegistroLoading"
                            >
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>


                <v-row class="mt-4">
                    <v-col cols="12" md="12" sm="12">
                        <v-subheader class="text-h6 black--text">
                            Vinculaciones registradas 
                        </v-subheader>
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-row>
                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headersCoberturas"
                            :items="coberturas"
                            :loading="tableCoberturaLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                    <tr>
                                       
                                        <td>{{ item.metaPND }}</td>
                                        <td> {{ item.metaODS }}</td>
                                        <td>{{ item.metaKatun }}</td>
                                        <!--<td>
                                            <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                            </v-chip>
                                        </td>-->
                                        
                                        <td>
                                            <v-btn v-if="item.estadosId==1"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                :disabled="btnEliminarCoberturaDisabled"
                                                :loading="btnEliminarCoberturaDisabled"
                                                color="blue-grey lighten-5"
                                                @click="eliminarItem(item.id)"
                                                >
                                                <v-icon left>mdi-delete</v-icon> Eliminar cobertura
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                  </v-row>
            </v-card-text>
        </v-card>
    </div>
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
</div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import {OBTENER_COBERTURAS_CNS, REGISTRAR_COBERTURA_CNS, ELIMINAR_COBERTURA_CNS } from "@/core/services/store/proyectoscns/coberturas/coberturacns.module";

export default{
    name: "SeccionVinculacionProyectosAlianza",
     props: ['alianzaId', 'proyectoId', 'negociacionId'],

    components: {
        DialogLoader,
        SnackAlert
    },
    data(){
        return {
            skeletonLoading:false,
            validForm: false,
            btnRegistroLoading:false,
            tableCoberturaLoading: false,
            btnEliminarCoberturaDisabled: false,
            coberturas: [],
            pnds:[],
            ddPndLoading: false,
            ddMetasPndLoading: false,
            ddOdsLoading: false,
            metasOds:[],
            ddMetasOdsLoading: false,
            ejesKatun:[],
            ddEjesKatunLoading: false,
            prioridadesKatun:[],
            ddPrioridadesKatunLoading: false,
            metasKatun: [],
            ddMetasKatunLoading: false,
            pggs:[],
            ddPggLoading: false,
            pilaresPgg:[],
            ddPilaresPggLoading: false,
            metasEstrategicasPgg:[],
            ddMetasEstrategicasPggLoading: false,
            objetivosEstrategicosPgg:[],
            ddObjetivosEstrategicosPggLoading: false,
            objetivosSectorialesPgg:[],
            ddObjetivosSectorialesPggLoading: false,
            datosCobertura: {
                poryectosAlianzasId: this.proyectoId,
                id: 0,
                //municipiosId: 0,
                pndId: 0,
                metaPNDId: 0,
                prioridadesKatunId: 0,
                ejesKatunId: 0,
                metasKatunId: 0,
                odsId: 0,
                metasODSId: 0,
                pggId: 0,
                pilarPGGId: 0,
                metasEstrategicasPGGId: 0,
                odsSecundarioId: 0,
                objetivosSectorialesPGGId: 0,
                objetivosEstrategicosPGGId: 0,
                estadosId: 1,
                usuarioCreacion: "admin",
                
            },
              ...validations,
        }
    },

    methods: {
        //Restablecer los campos
        //El tipo se usa para poner la animacion de todo el card (1) o solo la tabla (2)
        resetItems(tipo){
            
            if(tipo===1){
                this.skeletonLoading=true;
            }
            this.tableCoberturaLoading=true;
                        
            this.datosCobertura= {
                proyectosAlianzasId: this.proyectoId,
                id: 0,
               // municipiosId: 0,
                pndId: 0,
                metaPNDId: 0,
                prioridadesKatunId: 0,
                ejesKatunId: 0,
                metasKatunId: 0,
                odsId: 0,
                metasODSId: 0,
                pggId: 0,
                pilarPGGId: 0,
                metasEstrategicasPGGId: 0,
                odsSecundarioId: 0,
                objetivosSectorialesPGGId: 0,
                objetivosEstrategicosPGGId: 0,
                estadosId: 1,
                usuarioCreacion: "admin",
                
            };
        },


         //obtener las PNDS
        async obtenerPnds() {
            this.ddPndLoading=true;
            this.pnds = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'PND/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.pnds = res.data;

                }
                this.ddPndLoading=false;
            })
            .catch(() => {
                this.pnds = [];
                this.ddPndLoading=false;
            });
        
        },

        //obtenerMetasPnd por pnd id
        async obtenerMetasPnd(pndId){
             this.ddMetasPndLoading=true;
            this.metasPnd = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `MetaPND/all/1/${pndId}`})
            .then(res => {
                if (res.status === 200) {
                    this.metasPnd = res.data;

                }
                this.ddMetasPndLoading=false;
            })
            .catch(() => {
                this.pnds = [];
                this.ddMetasPndLoading=false;
            });
        },


        //Obtener las ods
        async obtenerOds(){
             this.ddOdsLoading=true;
            this.ods = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ODS/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.ods = res.data;

                }
                this.ddOdsLoading=false;
            })
            .catch(() => {
                this.pnds = [];
                this.ddOdsLoading=false;
            });
        },


        //Obtener metas ods por ods id
        async obtenerMetasOds(odsId){
             this.ddMetasOdsLoading=true;
            this.metasOds = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `MetasODS/all/1/${odsId}`})
            .then(res => {
                if (res.status === 200) {
                    this.metasOds = res.data;

                }
                this.ddMetasOdsLoading=false;
            })
            .catch(() => {
                this.metasOds = [];
                this.ddMetasOdsLoading=false;
            });
        },
        

        //Obtener los ejes katun
        async obtenerEjesKatun(){
             this.ddEjesKatunLoading=true;
            this.ejesKatun = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `EjesKatun/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.ejesKatun = res.data;

                }
                this.ddEjesKatunLoading=false;
            })
            .catch(() => {
                this.ejesKatun = [];
                this.ddEjesKatunLoading=false;
            });
        },

        //Obtener las metas katun
        async obtenerPrioridadesKatun(katunId){
             this.ddPrioridadesKatunLoading=true;
            this.prioridadesKatun = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `PrioridadesKatun/all/1/${katunId}`})
            .then(res => {
                if (res.status === 200) {
                    this.prioridadesKatun = res.data;

                }
                this.ddPrioridadesKatunLoading=false;
            })
            .catch(() => {
                this.prioridadesKatun = [];
                this.ddPrioridadesKatunLoading=false;
            });
        },


        //Obtener las metas katun
        async obtenerMetasKatun(prioridadKatunId){
             this.ddMetasKatunLoading=true;
             this.datosCobertura.metasKatunId=0;
            this.metasKatun = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `MetasKatun/all/1/${prioridadKatunId}`})
            .then(res => {
                if (res.status === 200) {
                    this.metasKatun = res.data;

                }
                this.ddMetasKatunLoading=false;
            })
            .catch(() => {
                this.prioridadesKatun = [];
                this.ddMetasKatunLoading=false;
            });
        },


        //Obtener pgg
        async obtenerPgg(){
            this.pggs = [];
            this.pilaresPgg = [];
            this.metasEstrategicasPgg = [];
            this.objetivosEstrategicosPgg = [];
            this.objetivosSectorialesPgg = [];

            this.pggSeleccionada={};
            this.ddPggLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `PGG/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.pggs = res.data;

                }
                this.ddPggLoading=false;
            })
            .catch(() => {
                this.pggs = [];
                this.ddPggLoading=false;
            });
        },

        //Obtener los pilares segun pgg
        async obtenerPilaresPgg(pggId){
            this.pilaresPgg = [];
            this.metasEstrategicasPgg = [];
            this.objetivosEstrategicosPgg = [];
            this.objetivosSectorialesPgg = [];

            this.pilarPggSeleccionado = {};
            this.ddPilaresPggLoading=true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `PilarPGG/all/1/${pggId}`})
            .then(res => {
                if (res.status === 200) {
                    this.pilaresPgg = res.data;

                }
                this.ddPilaresPggLoading=false;
            })
            .catch(() => {
                this.pilaresPgg = [];
                this.ddPilaresPggLoading=false;
            });
        },

        //Obtener las metas estrategicas pgg por pilar pgg id
        async obtenerMetasEstrategicas(pilarPggId){
            this.metaEstrategicaPggSeleccionada = {};
            this.ddMetasEstrategicasPggLoading=true;
            this.objetivosEstrategicosPgg = [];
            this.objetivosSectorialesPgg = [];
            this.metasEstrategicasPgg = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `MetasEstrategicasPGG/all/1/${pilarPggId}`})
            .then(res => {
                if (res.status === 200) {
                    this.metasEstrategicasPgg = res.data;

                }
                this.ddMetasEstrategicasPggLoading=false;
            })
            .catch(() => {
                this.metasEstrategicasPgg = [];
                this.ddMetasEstrategicasPggLoading=false;
            });
        },


        //Obtener los objetivos estrategicos PGG por meta PGG id
        async obtenerObjetivosEstrategicosPgg(metaEstrategicaPggId){
            this.objetivoEstrategicoPggSeleccionado ={};
             this.ddObjetivosEstrategicosPggLoading=true;
            this.objetivosEstrategicosPgg = [];
            this.objetivosSectorialesPgg = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ObjetivosEstrategicosPGG/all/1/${metaEstrategicaPggId}`})
            .then(res => {
                if (res.status === 200) {
                    this.objetivosEstrategicosPgg = res.data;

                }
                this.ddObjetivosEstrategicosPggLoading=false;
            })
            .catch(() => {
                this.objetivosEstrategicosPgg = [];
                this.ddObjetivosEstrategicosPggLoading=false;
            });


        },

        //Obteener los objetivos secotriales PGG por objetivo estrategico pgg Id
        async obtenerObjetivosSectorialessPgg(objetivoEstrategicoId){
            this.ddObjetivosSectorialesPggLoading=true;
            //this.datosCobertura.ObjetivosSectorialesPGGId =0;
            this.objetivosSectorialesPgg = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ObjetivosSectorialesPGG/all/1/${objetivoEstrategicoId}`})
            .then(res => {
                if (res.status === 200) {
                    this.objetivosSectorialesPgg = res.data;

                }
                this.ddObjetivosSectorialesPggLoading=false;
            })
            .catch(() => {
                this.objetivosSectorialesPgg = [];
                this.ddObjetivosSectorialesPggLoading=false;
            });
        },
            

        //Registrar Datos de cobertura
        async registrarCobertura(){
            this.btnRegistroLoading=true;
            this.datosCobertura.estadosId= 1;
            this.datosCobertura.usuarioCreacion ='admin';
            this.datosCobertura.proyectosAlianzasId = this.proyectoId;

            //console.log(this.datosCobertura.monto)
            //Validar monto que sea mayor a cero 00
            if(parseFloat(this.datosCobertura.monto) <= 0){
                this.$refs.snackalert.SnackbarShow("warning", "Alerta", "El monto debe ser mayor a 0.00");
            }

            if(this.datosCobertura.odsSecundarioId === 0 || !this.datosCobertura.odsSecundarioId){
                delete this.datosCobertura.odsSecundarioId;
            }
            
            if(this.datosCobertura.objetivosEstrategicosPGGId === 0 || !this.datosCobertura.objetivosEstrategicosPGGId){
                delete this.datosCobertura.objetivosEstrategicosPGGId;
            }

            if(this.datosCobertura.objetivosSectorialesPGGId === 0 || !this.datosCobertura.objetivosSectorialesPGGId){
                delete this.datosCobertura.objetivosSectorialesPGGId;
            }

            //console.log(this.datosCobertura);
           
            await this.$store
            .dispatch(REGISTRAR_COBERTURA_CNS, {datos: this.datosCobertura})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerCoberturaProyecto(this.proyectoId);             
                    this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroLoading=false;
                this.skeletonLoading = false;
            })
            .catch(error => {
                this.skeletonLoading = false;
                 this.btnRegistroLoading=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },

        //Eliminar registro de cobertura
        async eliminarItem(id){
            this.btnEliminarCoberturaDisabled = true;

            await this.$store
            .dispatch(ELIMINAR_COBERTURA_CNS, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerCoberturaProyecto(this.proyectoId);             
                   // this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarCoberturaDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarCoberturaDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },

        /*
        // Obtener los registros de cobertura almacenados para el proyecto
        */
        async obtenerCoberturaProyecto(proyectoId){
            
            this.tableCoberturaLoading=true;
            this.coberturas = [];
            await this.$store
            .dispatch(OBTENER_COBERTURAS_CNS, {tipo: 5, id: proyectoId})
            .then(res => {
                if (res.status === 200) {
                    this.coberturas = res.data;

                }
                this.tableCoberturaLoading=false;
                this.skeletonLoading=false;
            })
            .catch(() => {
                this.coberturas = [];
                this.tableCoberturaLoading=false;
                this.skeletonLoading=false;
            });
        },




    },

    created(){
        this.resetItems(1)
        this.obtenerPnds();
        this.obtenerOds();
        this.obtenerEjesKatun();
        this.obtenerPgg();
        this.obtenerCoberturaProyecto(this.proyectoId);
    },

    computed: {

        headersCoberturas(){
            return [
                 {
                    text: "Id",
                    //align: "start",
                    sortable: false,
                    value: "id",
                    align: ' d-none'
                },
                {
                    text: "Meta PND",
                    align: "start",
                    sortable: true,
                    value: "nombre"
                },
                {
                    text: "Meta ODS",
                    align: "start",
                    sortable: true,
                    value: "correo"
                },
                {
                    text: "Meta K`atun",
                    align: "start",
                    sortable: true,
                    value: "metaKatun"
                },
               
                {
                    text: "Acciones",
                    align: "start",
                    sortable: true,
                    value: ""
                },
            ]
        }

    }
}


</script>
