var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Vinculación Marcos de desarrollo ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-form',{ref:"formCobertura",on:{"submit":function($event){$event.preventDefault();return _vm.registrarCobertura.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.pnds,"loading":_vm.ddPndLoading,"dense":"","filled":"","label":"PND","item-text":"nombrePND","item-value":"id","no-data-text":_vm.pnds != null
                                    ? 'Seleccione PND'
                                    : 'No se han encontrado PND',"menu-props":"auto","rules":[
                                    _vm.selectRequired('pnd')
                                ]},on:{"change":function($event){return _vm.obtenerMetasPnd(_vm.datosCobertura.pndId)}},model:{value:(_vm.datosCobertura.pndId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "pndId", $$v)},expression:"datosCobertura.pndId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.metasPnd,"loading":_vm.ddMetasPndLoading,"dense":"","filled":"","label":"Meta PND","item-text":"meta","item-value":"id","no-data-text":_vm.metasPnd != null
                                    ? 'Seleccione una meta PND'
                                    : 'No se han encontrado metas',"menu-props":"auto","rules":[
                                    _vm.selectRequired('meta pnd')
                                ]},model:{value:(_vm.datosCobertura.metaPNDId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "metaPNDId", $$v)},expression:"datosCobertura.metaPNDId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.ods,"loading":_vm.ddOdsLoading,"dense":"","filled":"","label":"ODS","item-text":"nombreODS","item-value":"id","no-data-text":_vm.pnds != null
                                    ? 'Seleccione ODS'
                                    : 'No se han encontrado ODS',"menu-props":"auto","rules":[
                                    _vm.selectRequired('Ods primario')
                                ]},on:{"change":function($event){return _vm.obtenerMetasOds(_vm.datosCobertura.odsId)}},model:{value:(_vm.datosCobertura.odsId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "odsId", $$v)},expression:"datosCobertura.odsId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.metasOds,"loading":_vm.ddMetasOdsLoading,"dense":"","filled":"","label":"Meta ODS","item-text":"meta","item-value":"id","no-data-text":_vm.metasOds != null
                                    ? 'Seleccione una meta ODS'
                                    : 'No se han encontrado metas ODS',"menu-props":"auto","rules":[
                                    _vm.selectRequired('meta ods')
                                ]},model:{value:(_vm.datosCobertura.metasODSId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "metasODSId", $$v)},expression:"datosCobertura.metasODSId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.ods,"loading":_vm.ddOdsLoading,"dense":"","filled":"","label":"ODS Secundario","item-text":"nombreODS","item-value":"id","no-data-text":_vm.pnds != null
                                    ? 'Seleccione ODS secundario'
                                    : 'No se han encontrado ODS',"menu-props":"auto"},model:{value:(_vm.datosCobertura.odsSecundarioId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "odsSecundarioId", $$v)},expression:"datosCobertura.odsSecundarioId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.ejesKatun,"loading":_vm.ddEjesKatunLoading,"dense":"","filled":"","label":"Eje K´atun","item-text":"nombreEje","item-value":"id","no-data-text":_vm.ejesKatun != null
                                    ? 'Seleccione Eje K´atun'
                                    : 'No se han encontrado Ejes K´atun',"menu-props":"auto","rules":[
                                    _vm.selectRequired('Eje K´atun')
                                ]},on:{"change":function($event){return _vm.obtenerPrioridadesKatun(_vm.datosCobertura.ejesKatunId)}},model:{value:(_vm.datosCobertura.ejesKatunId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "ejesKatunId", $$v)},expression:"datosCobertura.ejesKatunId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.prioridadesKatun,"loading":_vm.ddPrioridadesKatunLoading,"dense":"","filled":"","label":"Prioridad K´atun","item-text":"prioridad","item-value":"id","no-data-text":_vm.prioridadesKatun != null
                                    ? 'Seleccione una prioridad K´atun'
                                    : 'No se han encontrado prioridades K´atun',"menu-props":"auto","rules":[
                                    _vm.selectRequired('prioridad K´atun')
                                ]},on:{"change":function($event){return _vm.obtenerMetasKatun(_vm.datosCobertura.prioridadesKatunId)}},model:{value:(_vm.datosCobertura.prioridadesKatunId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "prioridadesKatunId", $$v)},expression:"datosCobertura.prioridadesKatunId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.metasKatun,"loading":_vm.ddMetasKatunLoading,"dense":"","filled":"","label":"Meta K´atun","item-text":"meta","item-value":"id","no-data-text":_vm.metasKatun != null
                                    ? 'Seleccione una meta K´atun'
                                    : 'No se han encontrado metas K´atun',"menu-props":"auto","rules":[
                                    _vm.selectRequired('Meta k´atun')
                                ]},model:{value:(_vm.datosCobertura.metasKatunId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "metasKatunId", $$v)},expression:"datosCobertura.metasKatunId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.pggs,"loading":_vm.ddPggLoading,"dense":"","filled":"","label":"PGG","item-text":"nombrePGG","item-value":"id","no-data-text":_vm.pggs != null
                                    ? 'Seleccione pgg'
                                    : 'No se han encontrado pgg',"menu-props":"auto","rules":[
                                    _vm.selectRequired('pgg')
                                ]},on:{"change":function($event){return _vm.obtenerPilaresPgg(_vm.datosCobertura.pggId)}},model:{value:(_vm.datosCobertura.pggId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "pggId", $$v)},expression:"datosCobertura.pggId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.pilaresPgg,"loading":_vm.ddPilaresPggLoading,"dense":"","filled":"","label":"Pilar PGG","item-text":"pilar","item-value":"id","no-data-text":_vm.pilaresPgg != null
                                    ? 'Seleccione un pilar pgg'
                                    : 'No se han encontrado pilares pgg',"menu-props":"auto","rules":[
                                    _vm.selectRequired('pilar pgg')
                                ]},on:{"change":function($event){return _vm.obtenerMetasEstrategicas(_vm.datosCobertura.pilarPGGId)}},model:{value:(_vm.datosCobertura.pilarPGGId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "pilarPGGId", $$v)},expression:"datosCobertura.pilarPGGId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.metasEstrategicasPgg,"loading":_vm.ddMetasEstrategicasPggLoading,"dense":"","filled":"","label":"Meta estretégica PGG","item-text":"meta","item-value":"id","no-data-text":_vm.metasEstrategicasPgg != null
                                    ? 'Seleccione una meta estraégica pgg'
                                    : 'No se han encontrado metas estratégicas pgg',"menu-props":"auto","rules":[
                                    _vm.selectRequired('Meta estrategica pgg')
                                ]},on:{"change":function($event){return _vm.obtenerObjetivosEstrategicosPgg(_vm.datosCobertura.metasEstrategicasPGGId)}},model:{value:(_vm.datosCobertura.metasEstrategicasPGGId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "metasEstrategicasPGGId", $$v)},expression:"datosCobertura.metasEstrategicasPGGId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.objetivosEstrategicosPgg,"loading":_vm.ddObjetivosEstrategicosPggLoading,"dense":"","filled":"","label":"Objetivo estretégico PGG","item-text":"objetivo","item-value":"id","no-data-text":_vm.objetivosEstrategicosPgg != null
                                    ? 'Seleccione un objetivo estratégico pgg'
                                    : 'No se han encontrado objetivos estratégicos pgg',"menu-props":"auto"},on:{"change":function($event){return _vm.obtenerObjetivosSectorialessPgg(_vm.datosCobertura.objetivosEstrategicosPGGId)}},model:{value:(_vm.datosCobertura.objetivosEstrategicosPGGId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "objetivosEstrategicosPGGId", $$v)},expression:"datosCobertura.objetivosEstrategicosPGGId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.objetivosSectorialesPgg,"loading":_vm.ddObjetivosSectorialesPggLoading,"dense":"","filled":"","label":"Objetivo sectorial pgg","item-text":"objetivo","item-value":"id","no-data-text":_vm.objetivosSectorialesPgg != null
                                    ? 'Seleccione un objetivo sectorial pgg'
                                    : 'No se han encontrado objetivos sectoriales pgg',"menu-props":"auto"},model:{value:(_vm.datosCobertura.objetivosSectorialesPGGId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "objetivosSectorialesPGGId", $$v)},expression:"datosCobertura.objetivosSectorialesPGGId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right mt-2",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Vinculaciones registradas ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCoberturas,"items":_vm.coberturas,"loading":_vm.tableCoberturaLoading,"hide-default-footer":"","footer-props":{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.metaPND))]),_c('td',[_vm._v(" "+_vm._s(item.metaODS))]),_c('td',[_vm._v(_vm._s(item.metaKatun))]),_c('td',[(item.estadosId==1)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarCoberturaDisabled,"loading":_vm.btnEliminarCoberturaDisabled,"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarItem(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar cobertura ")],1):_vm._e()],1)])]}}],null,false,2738122107)})],1)],1)],1)],1)],1):_vm._e(),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }