<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">
    <!--inicio:: condiciones de instituciones beneficiarias -->
        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                        <v-subheader class="text-h5 black--text">
                            Cobertura
                        </v-subheader>
                    </v-col>
                    </v-row>
                    <v-form ref="form" v-on:submit.prevent="registrarCobertura" v-model="validFormCobertura" v-if="tipo===2">
                        <v-row class="mt-6">
                            <!--inicio:: campo-->
                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosCobertura.departamentosId"
                                :items="departamentos"
                                color="blue-grey lighten-2"
                                label="Seleccionar departamento"
                                item-text="departamento"
                                item-value="id"
                                menu-props="auto"
                                :rules="[selectRequired('departamento')]"
                                @change="obtenerMunicipiosPorDepartamento"
                            >
                            </v-select>
                            </v-col>
                            <!--fin:: campo-->

                            <!--inicio:: campo-->
                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                :loading="ddMunicipiosLoading"
                                v-model="datosCobertura.municipiosId"
                                :items="municipios"
                                color="blue-grey lighten-2"
                                label="Seleccionar Municipio"
                                item-text="municipio"
                                item-value="id"
                                :rules="[selectRequired('municipio')]"
                                menu-props="auto"
                            >
                            </v-select>
                            </v-col>
                            <!--fin:: campo-->
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-spacer></v-spacer>
                            <v-btn
                                class="ma-0 white--text float-right"
                                medium
                                type="submit"
                                color="light-blue-502"
                                :disabled="!validFormCobertura"
                                :loading="btnRegistroCoberturaLoading"
                            >
                                <v-icon left>mdi-plus</v-icon> Agregar Cobertura
                            </v-btn>
                            </v-col>

                        </v-row>

                    </v-form>

                    <v-row class="mt-4">

                        <v-col cols="12" sm="12" md="12" v-if="tipo===2">
                            <v-subheader class="text-h6 black--text">
                                Cobertura registrada
                            </v-subheader>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                        <template>
                            <v-data-table
                            :headers="headersCobertura"
                            :items="coberturas"
                            :items-per-page="5"
                            :loading="tableCoberturasLoading"
                            class="elevation-1"
                            >
                            <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.departamento }}</td>
                                <td>{{ item.municipio }}</td>
                                <!--<td>{{ item.area }}</td>
                                <td>
                                <v-chip
                                    class="ma-2 font-weight-medium"
                                    label
                                    :color="
                                    item.estadoId === 1
                                        ? 'blue-grey lighten-4'
                                        : item.estadoId === 2
                                        ? 'blue lighten-4'
                                        : item.estadoId === 6
                                        ? 'cyan lighten-4'
                                        : item.estadoId === 7
                                        ? 'deep-orange lighten-4'
                                        : 'pink lighten-4'
                                    "
                                    :text-color="
                                    item.estadoId === 1
                                        ? 'blue-grey lighten-1'
                                        : item.estadoId === 2
                                        ? 'blue lighten-1'
                                        : item.estadoId === 6
                                        ? 'cyan darken-1'
                                        : item.estadoId === 7
                                        ? 'deep-orange lighten-1'
                                        : 'pink darken-1'
                                    "
                                    small
                                >
                                    {{ item.estado }}
                                </v-chip>
                                </td>-->
                                <td>
                                <v-btn
                                    v-if="tipo===2"
                                    class=" ma-2
                                    btn-bg-light
                                    blue-grey--text
                                    lighten-2--text
                                    font-weight-medium
                                    text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    @click="eliminarCobertura(item.id)"
                                >
                                    <v-icon left>mdi-delete</v-icon> Eliminar
                                </v-btn>

                                <v-btn v-if="item.estadoId===1 && tipo===2"
                                    class=" ma-2
                                    btn-bg-light
                                    blue-grey--text
                                    lighten-2--text
                                    font-weight-medium
                                    text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    @click="mostrarFormularioProductos(item.id)"
                                >
                                    <v-icon left>mdi-plus</v-icon> Agregar Productos
                                </v-btn>
                                </td>
                            </tr>
                            </template>
                            </v-data-table>
                        </template>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> 

        <!--Dialog loader -->
        <DialogLoader
            :dialogVisible="dialogLoaderVisible"
            :text="dialogLoaderText"
            transition="scroll-y-transition"
        ></DialogLoader>
        <!---->

        <!--Inicio:: Snack alert-->
        <SnackAlert ref="snackalert"></SnackAlert>
        <!-- Fin:: Snack alert-->
    </div>

</div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO, OBTENER_SUBITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_COBERTURAS_PROYECTO_ALIANZA, REGISTRAR_COBERTURA_PROYECTO_ALIANZA, ELIMINAR_COBERTURA_PROYECTO_ALIANZA } from "@/core/services/store/alianzas/proyectos/coberturaproyectosalianza/coberturaproyectoalianza.module";

export default {
    name: "SeccionCoberturaProyectosAlianza",
    props: ['alianzaId', 'proyectoAlianzaId', 'tipo'], //Tipo 1 segepla, 2 externo, si es 2 bloquear los controles
    components: {
        DialogLoader,
        SnackAlert
    },
    data(){
        return{
            skeletonLoading: false,
            coberturas:[],
            tableCoberturasLoading: false,
            departamentos: [],
            validFormCobertura:false,
            btnRegistroCoberturaLoading: false,
            dialogLoaderVisible: false,
            dialogLoaderText: "",
            municipios: [],
            ddMunicipiosLoading: false,
            datosCobertura: {
                municipiosId: 0,
                departamentosId: 0,
                proyectosAlianzasId: this.proyectoAlianzaId,
                estadosId: 1,
                area: "",
                usuarioCreacion: "admin",
            },
            ...validations

        }
    },

    methods:{ 

        resetItems(tipo){
            if(tipo==1){
                this.skeletonLoading=true;
            }

            this.datosCobertura= {
                municipiosId: 0,
                departamentosId: 0,
                proyectosAlianzasId: this.alianzaId,
                estadosId: 1,
                area: "",
                usuarioCreacion: "admin",
            };
        },

        
        async obtenerDepartamentos() {
            this.departamentos = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Departamentos/all/1/94'})
                .then(res => {
                if (res.status === 200) {
                    this.departamentos = res.data;
                }
                })
                .catch(() => {
                    this.departamentos = [];
                });
            },

        async obtenerMunicipiosPorDepartamento() {
            this.municipios = [];
            this.ddMunicipiosLoading = true;
            await this.$store
                .dispatch(OBTENER_SUBITEMS_CATALOGO, {id: this.datosCobertura.departamentosId ,endpoint: 'Municipios/all/1'})
                .then(res => {
                if (res.status === 200) {
                    this.municipios = res.data;
                }
                this.ddMunicipiosLoading = false;
                })
                .catch(() => {
                    this.municipios = [];
                    this.ddMunicipiosLoading = false;
                });
            
            },  

        //Obtener las coberturas registradas
        async obtenerCoberturas(alianzaId){
            this.coberturas = [];
            this.tableCoberturasLoading = true;
            
            this.$store
            .dispatch(OBTENER_COBERTURAS_PROYECTO_ALIANZA, {estadoId: 1, proyectoId: alianzaId})
            .then(res => {
                //  console.log(res)
                if(res.status===200){
                    this.coberturas = res.data;
                    //this.datosItem.area === "Rural"  ? this.areaSeleccionada = {id: 1, nombreArea: this.datosItem.area}  : this.areaSeleccionada = {id: 2, nombreArea: this.datosItem.area};
                }
                this.tableCoberturasLoading = false;
                this.skeletonLoading=false;
            })
            .catch(() => {
            this.tableCoberturasLoading = false;
            });

        },


        ///Registrar datos de cobertura
        async registrarCobertura(){
            this.btnRegistroCoberturaLoading=true;

            this.datosCobertura.area = "";
            this.datosCobertura.proyectosAlianzasId = this.proyectoAlianzaId;
                await this.$store
                .dispatch(REGISTRAR_COBERTURA_PROYECTO_ALIANZA, { datos: this.datosCobertura })
                .then(res => {
                    //console.log(res)
                    if(res.status===200){
                        // this.obtnenerItems();
                        // this.dialog=false;
                        this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                        
                    //  this.switchItemEstado = true;
                    //  this.datosIndicador.detalleIndicador = '';
                        this.resetItems(2);
                        this.btnRegistroCoberturaLoading=false;
                        this.obtenerCoberturas(this.proyectoAlianzaId);
                    } else {
                        this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                    }
                    this.btnRegistroCoberturaLoading=false;
                })
                .catch(error => {
                    //console.log(error)
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                    this.btnRegistroCoberturaLoading=false;
                });
        },



        //Eliminar registro de cobertura
        async eliminarCobertura(id) {
            this.btnRegistroCoberturaLoading=true;
            this.datosCobertura.iniciativaId = this.iniciativaId;
            await this.$store
            .dispatch(ELIMINAR_COBERTURA_PROYECTO_ALIANZA, id)
            .then(res => {
                //console.log(res)
                if(res.status===200){
                   // this.obtnenerItems();
                   // this.dialog=false;
                    this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                   
                  //  this.switchItemEstado = true;
                  //  this.datosIndicador.detalleIndicador = '';
                    this.btnRegistroCoberturaLoading=false;
                    this.obtenerCoberturas(this.proyectoAlianzaId);
                } else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                }
                this.btnRegistroCoberturaLoading=false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                this.btnRegistroCoberturaLoading=false;
            });
    },


    },

    created() {
        this.resetItems(2);
        this.obtenerDepartamentos();
        this.obtenerCoberturas(this.proyectoAlianzaId);
    },

    computed:{
        headersCobertura() {
            return [
                {
                text: "Departamento",
                align: "start",
                sortable: false,
                value: "Departamento"
                },
                {
                text: "Municipio",
                align: "start",
                sortable: true,
                value: "Municipio"
                },
            
                {
                text: "Acciones",
                align: "start",
                sortable: false,
                value: ""
                }
            ];
        },
    }
}
</script>